
import { Component, Vue } from 'nuxt-property-decorator'
import Item from './Item.vue'

@Component({
  components: {
    Item
  }
})
export default class extends Vue {
  isCollapse: boolean = true
}
